<template>
  <base-block :title="$t('order.data.title')" header-class="bg-primary" themed>
    <b-table-simple responsive borderless small table-class="table-vcenter">
      <b-tbody>
        <!-- commission -->
        <b-tr>
          <b-td class="font-w600" style="width: 25%">
            {{ $t("order.data.commission") }}:
          </b-td>
          <b-td>
            <span v-if="order.commission != ''">
              {{ order.commission }}
            </span>
            <span v-else>{{ $t("order.data.notSpecified") }}</span>
          </b-td>
        </b-tr>

        <!-- Delivery date customer -->
        <b-tr>
          <b-td class="font-w600" style="width: 25%">
            {{ $t("order.data.deliveryDateCustomer") }}:
          </b-td>
          <b-td>
            <span v-if="order.delivery_date_customer !== null">{{
              $d(new Date(order.delivery_date_customer), "short")
            }}</span>
            <span v-else>{{ $t("order.data.notSpecified") }}</span>
          </b-td>
        </b-tr>

        <!-- Delivery date supplier -->
        <b-tr>
          <b-td class="font-w600" style="width: 25%">
            {{ $t("order.data.deliveryDate") }}:
          </b-td>
          <b-td>
            <span v-if="order.delivery_date_supplier != null">{{
              $d(new Date(order.delivery_date_supplier), "short")
            }}</span>
            <span v-else>{{ $t("order.data.notSpecified") }}</span>
            <i
              v-if="order.status.sent_to_supplier == 0"
              class="far fa-edit ml-2 p-2 cursor-pointer"
            />
          </b-td>
        </b-tr>

        <b-tr>
          <b-td class="font-w600" style="width: 25%">
            {{ $t("order.data.notes") }}:
          </b-td>
          <b-td>
            <span>{{ order.notes }}</span>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </base-block>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  order: Object,
});

const order = props.order;

order.deliveryDateCustomer = order.delivery_date_customer;
order.deliveryDateSupplier = order.delivery_date_supplier;
order.notes = order.additional_information;
</script>
