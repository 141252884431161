<template>
  <section>
    <!-- Hero -->
    <base-page-heading :title="'Archiv Auftrag ' + orderId">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item
            active
            v-b-tooltip.hover.top="$d(new Date(order.status.created), 'long')"
            >{{
              $d(new Date(order.status.created), "short")
            }}</b-breadcrumb-item
          >
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <!-- Main -->
    <div class="content">
      <b-row>
        <b-col sm="12">
          <OrderActions :order="order" division="egfArchive" />
          <OrderPrints :order="order" division="egfArchive" />
          <OrderDataEgfArchive :order="order" />
          <OrderArticleEgfArchive :order="order" />
        </b-col>
      </b-row>
    </div>
    <!-- END Main -->
  </section>
</template>

<script setup>
import { defineProps } from "vue";

import OrderActions from "@/components/OrderActions.vue";
import OrderPrints from "@/components/OrderPrints.vue";
import OrderDataEgfArchive from "./OrderDataEgfArchive.vue";
import OrderArticleEgfArchive from "./OrderArticleEgfArchive.vue";

const props = defineProps({
  orderData: Object,
});

const orderId = props.orderData.order_id;
const order = props.orderData.order;
</script>
