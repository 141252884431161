<template>
  <base-block
    :title="$t('order.actions.title')"
    header-class="bg-primary"
    themed
  >
    <!-- Actions for SHOP -->
    <section v-if="division == 'shop'">
      <p>
        <b-button
          size="md"
          variant="outline-secondary"
          class="m-1"
          @click="setShopStatus('sent_to_supplier', 1)"
          :disabled="order.status.sent_to_supplier == 1"
        >
          <i class="far fa-paper-plane mr-1"></i>
          {{ $t("order.actions.sendToManufacture") }}
        </b-button>
        <b-button
          size="md"
          variant="outline-secondary"
          class="m-1"
          @click="setShopStatus('in_shop', 1)"
          :disabled="
            order.status.sent_to_shop == 0 || order.status.in_shop == 1
          "
        >
          <i class="fa fa-house-user mr-1"></i>
          {{ $t("order.actions.inShop") }}
        </b-button>
        <b-button
          size="md"
          variant="outline-secondary"
          class="m-1"
          @click="setShopStatus('finished', 1)"
          :disabled="
            order.status.in_shop == 0 ||
              order.status.finished == 1 ||
              order.status.cancelled == 1
          "
        >
          <i class="far fa-check-circle mr-1"></i>
          {{ $t("order.actions.finishOrder") }}
        </b-button>
        <b-button
          size="md"
          variant="outline-secondary"
          class="m-1"
          @click="setShopStatus('cancelled', 1)"
          :disabled="
            order.status.sent_to_supplier == 1 ||
              order.status.finished == 1 ||
              order.status.cancelled == 1
          "
        >
          <i class="si si-close mr-1"></i>
          {{ $t("order.actions.cancelOrder") }}
        </b-button>
        <b-button
          v-if="order.status.cancelled == 1"
          size="md"
          variant="outline-secondary"
          class="m-1"
          @click="setShopStatus('cancelled', 0)"
        >
          <i class="si si-close mr-1"></i>
          {{ $t("order.actions.reactivateOrder") }}
        </b-button>
      </p>

      <hr v-if="order.order_type == 'A'" />

      <!-- Create Service and Sample Order (not visible for Korea)-->
      <p v-if="order.order_type == 'A'">
        <!-- Create Service Button -->
        <router-link
          v-if="shopId && orderId && isServiceAllowedByCountry === true"
          :to="{
            name: 'OrderCreateService',
            params: { shopid: shopId, orderid: orderId },
          }"
        >
          <b-button
            size="md"
            variant="outline-secondary"
            class="m-1"
            :disabled="
              order.status.production_finished == 0 ||
                order.status.cancelled == 1
            "
          >
            <i class="fa fa-hammer mr-1"></i>
            {{ $t("order.createServiceOrder") }}
          </b-button>
        </router-link>

        <!-- Create Sample Button -->
        <router-link
          v-if="shopId && orderId && isSampleAllowedByCountry === true"
          :to="{
            name: 'OrderCreateSample',
            params: { shopid: shopId, orderid: orderId },
          }"
        >
          <b-button
            size="md"
            variant="outline-secondary"
            class="m-1"
            :disabled="
              order.status.finished == 1 || order.status.cancelled == 1
            "
          >
            <i class="fa fa-ring mr-1"></i>
            {{ $t("order.createSampleOrder") }}
          </b-button>
        </router-link>
      </p>
    </section>

    <!-- Actions for MANUFACTURE -->

    <section v-else-if="division == 'manufacture'">
      <p>
        <b-button
          size="md"
          variant="outline-secondary"
          class="m-1"
          @click="setManufactureStatus('sent_to_supplier', 0)"
          :disabled="
            order.status.sent_to_supplier == 0 ||
              order.status.production_finished == 1
          "
        >
          <i class="fas fa-window-close mr-1"></i>
          Auftrag komplett abbrechen
        </b-button>

        <b-button
          size="md"
          variant="outline-secondary"
          class="m-1"
          @click="setManufactureStatus('supplier_accepted', 1)"
          :disabled="
            order.status.sent_to_supplier == 0 ||
              order.status.supplier_accepted == 1
          "
        >
          <i class="fas fa-check mr-1"></i>
          Auftrag annehmen
        </b-button>

        <b-button
          size="md"
          variant="outline-secondary"
          class="m-1"
          @click="setManufactureStatus('in_production', 1)"
          :disabled="
            order.status.sent_to_supplier == 0 ||
              order.status.in_production == 1
          "
        >
          <i class="fas fa-hammer mr-1"></i>
          in Produktion
        </b-button>
        <b-button
          size="md"
          variant="outline-secondary"
          class="m-1"
          @click="setManufactureStatus('production_finished', 1)"
          :disabled="
            order.status.sent_to_supplier == 0 ||
              order.status.production_finished == 1 ||
              order.status.in_production == 0
          "
        >
          <i class="fas fa-check mr-1"></i>
          Produktion abgeschlossen
        </b-button>
        <b-button
          size="md"
          variant="outline-secondary"
          class="m-1"
          @click="setManufactureStatus('sent_to_shop', 1)"
          :disabled="
            order.status.sent_to_supplier == 0 ||
              order.status.sent_to_shop == 1 ||
              order.status.production_finished == 0
          "
        >
          <i class="far fa-paper-plane mr-1"></i>
          an Shop versendet
        </b-button>
      </p>
    </section>

    <!-- Actions for egfArchive -->
    <section v-else-if="division == 'egfArchive'">
      <p>
        <!-- Create Service Button -->
        <router-link
          v-if="shopId && orderId && isServiceAllowedByCountry === true"
          :to="{
            name: 'OrderCreateService',
            params: { shopid: shopId, orderid: orderId },
          }"
        >
          <b-button
            size="md"
            variant="outline-secondary"
            class="m-1"
            :disabled="
              order.status.production_finished == 0 ||
                order.status.cancelled == 1
            "
          >
            <i class="fa fa-hammer mr-1"></i>
            {{ $t("order.createServiceOrder") }}
          </b-button>
        </router-link>
      </p>
    </section>
  </base-block>
</template>

<script>
import Order from "@/assets/js/Order";
import ManufactureOrder from "@/assets/js/ManufactureOrder";

export default {
  name: "OrderActions",
  props: {
    order: {
      type: Object,
      required: true,
    },
    division: {
      /* possible values: shop, manufacture */
      type: String,
      required: true,
    },
  },

  methods: {
    setShopStatus(type, value) {
      Order.setStatus(this.shopId, this.orderId, type, value)
        .then(data => {
          if (data.ok == true) {
            let type = data.status_type;
            let date = data.status_type + "_date";

            this.orderData.status[type] = data.status_value;
            this.orderData.status[date] = data.status_date;
          }
        })
        .catch(err => {
          if (err.error == 2041) {
            this.$swal(
              this.$t("notification.error"),
              this.$t("order.notification.deliveryDateNotInFuture"),
              "error",
            );
          } else {
            Notification.push(
              this.$t("notification.error"),
              this.$t("notification.changesNotSaved"),
              "danger",
            );
          }
        });
    },
    setManufactureStatus(type, value) {
      ManufactureOrder.setStatus(this.supplierId, this.orderId, type, value)
        .then(data => {
          if (data.ok == true) {
            let type = data.status_type;
            let date = data.status_type + "_date";

            this.orderData.status[type] = data.status_value;
            this.orderData.status[date] = data.status_date;

            // if the order is completely cancelled, reset all statuses in timeline
            if (type == "sent_to_supplier" && value == 0) {
              this.orderData.status.supplier_exported = 0;
              this.orderData.status.supplier_accepted = 0;
              this.orderData.status.in_production = 0;
              this.orderData.status.production_finished = 0;
              this.orderData.status.sent_to_shop = 0;
            }
          }
        })
        .catch(err => {
          console.log(err);
          Notification.push(
            this.$t("notification.error"),
            this.$t("notification.changesNotSaved"),
            "danger",
          );
        });
    },
  },
  computed: {
    /* Check if the country is Korea and disable button to create service orders */
    isServiceAllowedByCountry() {
      return this.order.country_code !== "KR";
    },
    /* Check if the country is Korea and disable button to create sample orders */
    isSampleAllowedByCountry() {
      return this.order.country_code !== "KR";
    },
  },

  data() {
    return {
      supplierId: 1000,
      shopId: null,
      orderId: this.$route.params.orderid,
      orderData: { ...this.order }, // Create a copy of the order prop
    };
  },

  mounted() {
    this.orderId = this.$route.params.orderid;
    this.shopId = this.order.shop_id;
  },
};
</script>
