<template>
  <section>
    <!-- Hero -->
    <base-page-heading
      :title="$t('order.title.type_' + order.order_type) + ' ' + orderId"
    >
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item
            active
            v-b-tooltip.hover.top="
              $d(new Date(order.status.created_date), 'long')
            "
            >{{
              $d(new Date(order.status.created_date), "short")
            }}</b-breadcrumb-item
          >
        </b-breadcrumb>
      </template>
      <template #sub v-if="order.parent_order_str">
        <i class="fa fa-long-arrow-alt-right" />
        {{ $t("order.parentOrder") }}

        <router-link
          class="font-w600"
          v-if="order.shop_id && order.parent_order_str"
          :to="{
            name: 'OrderDetail',
            params: {
              shopid: order.shop_id,
              orderid: order.parent_order_str,
            },
          }"
          @click.native="reload(order.shop_id, order.parent_order_str)"
        >
          {{ order.parent_order_str }}
        </router-link>
      </template>
      <template #sub v-else-if="order.origin == 'egfArchive'">
        <i class="fa fa-long-arrow-alt-right" />
        {{ $t("order.parentOrder") }}

        <a
          class="font-w600"
          :href="'/' + order.shop_id + '/orders/detail/' + order.origin_id"
          v-if="order.shop_id && order.origin == 'egfArchive'"
        >
          {{ order.origin_id }} (Archiv)
          <!-- TODO: Translate -->
        </a>
      </template>
      <template #sub v-else-if="order.child_orders.length > 0">
        <div
          v-for="(child_order, child_key) in order.child_orders"
          :key="child_key"
        >
          <i class="fa fa-long-arrow-alt-right" />
          {{ $t("order.title.type_" + child_order.order_type) }}
          <router-link
            class="font-w600"
            v-if="order.shop_id && child_order.order_id_str"
            :to="{
              name: 'OrderDetail',
              params: {
                shopid: order.shop_id,
                orderid: child_order.order_id_str,
              },
            }"
            @click.native="reload(order.shop_id, child_order.order_id_str)"
          >
            {{ child_order.order_id_str }}
          </router-link>
        </div>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <div class="content" v-if="isOrderIdValid === true">
      <b-row v-if="order.order_type == 'S'">
        <b-col sm="12">
          <OrderServiceHeader :order="order" />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <OrderStatusTimeline :order="order" division="shop" />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12">
          <OrderActions :order="order" division="shop" />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12">
          <OrderPrints :order="order" division="shop" />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12">
          <OrderCosts :order="order" />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12">
          <base-block
            :title="$t('order.data.title')"
            header-class="bg-primary"
            themed
          >
            <b-table-simple
              responsive
              borderless
              small
              table-class="table-vcenter"
            >
              <b-tbody>
                <!-- commission -->
                <b-tr>
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("order.data.commission") }}:
                  </b-td>
                  <b-td>
                    <span v-if="edit.commission !== true">
                      {{ order.commission }}
                      <i
                        class="far fa-edit ml-2 p-2 cursor-pointer"
                        @click="editInput('commission')"
                      />
                    </span>
                    <b-input-group v-else>
                      <b-form-input
                        type="text"
                        v-model="order.commission"
                        maxlength="50"
                        @keyup.enter="saveInput('commission')"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          size="md"
                          variant="alt-white"
                          @click="saveInput('commission')"
                        >
                          <i class="far fa-check-circle p-1" />
                        </b-button>
                        <b-button
                          size="md"
                          variant="alt-white"
                          @click="revertInput('commission')"
                        >
                          <i class="far fa-times-circle p-1" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-td>
                </b-tr>

                <!-- Delivery date customer -->
                <b-tr>
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("order.data.deliveryDateCustomer") }}:
                  </b-td>
                  <b-td>
                    <div v-if="edit.delivery_date_customer !== true">
                      <span v-if="order.delivery_date_customer !== null">{{
                        $d(new Date(order.delivery_date_customer), "short")
                      }}</span>
                      <span v-else>{{ $t("order.data.notSpecified") }}</span>
                      <i
                        class="far fa-edit ml-2 p-2 cursor-pointer"
                        @click="editInput('delivery_date_customer')"
                      />
                    </div>
                    <b-input-group v-else>
                      <flat-pickr
                        v-model="order.delivery_date_customer"
                        id="deliveryDateCustomer"
                        ref="FlatPickrDeliveryDateCustomer"
                        class="form-control-alt"
                        :placeholder="$t('order.data.preferredDate')"
                        name="deliveryDateCustomer"
                        :config="flatPickrConfig"
                      ></flat-pickr>
                      <b-input-group-append>
                        <b-button
                          size="md"
                          variant="alt-white"
                          @click="saveInput('delivery_date_customer')"
                        >
                          <i class="far fa-check-circle p-1" />
                        </b-button>
                        <b-button
                          size="md"
                          variant="alt-white"
                          @click="revertInput('delivery_date_customer')"
                        >
                          <i class="far fa-times-circle p-1" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-td>
                </b-tr>

                <!-- Delivery date supplier -->
                <b-tr>
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("order.data.deliveryDate") }}:
                  </b-td>
                  <b-td>
                    <div v-if="edit.delivery_date_supplier !== true">
                      <span v-if="order.delivery_date_supplier != null">{{
                        $d(new Date(order.delivery_date_supplier), "short")
                      }}</span>
                      <span v-else>{{ $t("order.data.notSpecified") }}</span>
                      <i
                        v-if="order.status.sent_to_supplier == 0"
                        class="far fa-edit ml-2 p-2 cursor-pointer"
                        @click="editInput('delivery_date_supplier')"
                      />
                    </div>
                    <b-input-group v-else>
                      <flat-pickr
                        v-model="order.delivery_date_supplier"
                        id="deliveryDateSupplier"
                        ref="FlatPickrDeliveryDateSupplier"
                        class="form-control-alt"
                        :placeholder="$t('order.data.preferredDate')"
                        name="deliveryDateSupplier"
                        :config="flatPickrConfigDeliveryDateSupplier"
                      ></flat-pickr>
                      <b-input-group-append>
                        <b-button
                          size="md"
                          variant="alt-white"
                          @click="saveInput('delivery_date_supplier')"
                        >
                          <i class="far fa-check-circle p-1" />
                        </b-button>
                        <b-button
                          size="md"
                          variant="alt-white"
                          @click="revertInput('delivery_date_supplier')"
                        >
                          <i class="far fa-times-circle p-1" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-td>
                </b-tr>
                <b-tr v-if="order.order_type == 'A'">
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("order.data.weddingDate") }}:
                  </b-td>
                  <b-td>
                    <div v-if="edit.wedding_date !== true">
                      <span v-if="order.wedding_date !== null">{{
                        $d(new Date(order.wedding_date), "short")
                      }}</span>
                      <span v-else>{{ $t("order.data.notSpecified") }}</span>
                      <i
                        class="far fa-edit ml-2 p-2 cursor-pointer"
                        @click="editInput('wedding_date')"
                      />
                    </div>
                    <b-input-group v-else>
                      <flat-pickr
                        v-model="order.wedding_date"
                        id="weddingDate"
                        ref="FlatPickrWeddingDate"
                        class="form-control-alt"
                        :placeholder="$t('order.data.preferredDate')"
                        name="weddingDate"
                        :config="flatPickrConfig"
                      ></flat-pickr>
                      <b-input-group-append>
                        <b-button
                          size="md"
                          variant="alt-white"
                          @click="saveInput('wedding_date')"
                        >
                          <i class="far fa-check-circle p-1" />
                        </b-button>
                        <b-button
                          size="md"
                          variant="alt-white"
                          @click="revertInput('wedding_date')"
                        >
                          <i class="far fa-times-circle p-1" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("order.data.servedBy") }}:
                  </b-td>
                  <b-td>
                    <span v-if="edit.salesperson !== true">
                      <span v-if="order.salesperson !== null">
                        {{ order.salesperson }}
                      </span>
                      <span v-else>{{ $t("order.data.notSpecified") }}</span>
                      <i
                        class="far fa-edit ml-2 p-2 cursor-pointer"
                        @click="editInput('salesperson')"
                      />
                    </span>
                    <b-input-group v-else>
                      <b-form-input
                        type="text"
                        v-model="order.salesperson"
                        maxlength="25"
                        @keyup.enter="saveInput('salesperson')"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          size="md"
                          variant="alt-white"
                          @click="saveInput('salesperson')"
                        >
                          <i class="far fa-check-circle p-1" />
                        </b-button>
                        <b-button
                          size="md"
                          variant="alt-white"
                          @click="revertInput('salesperson')"
                        >
                          <i class="far fa-times-circle p-1" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td colspan="2" class="pt-4 font-w600">
                    <b-form-group
                      :label="$t('order.data.notesCustomer')"
                      label-class="font-w600"
                    >
                      <b-form-textarea
                        id="notes"
                        rows="4"
                        :placeholder="$t('order.data.notesCustomerPlaceholder')"
                        v-model="order.notes"
                        :disabled="edit.notes !== true"
                      >
                      </b-form-textarea>
                      <span v-if="edit.notes !== true">
                        <b-button
                          class="m-1"
                          variant="outline-secondary"
                          @click.stop="editInput('notes')"
                        >
                          <i class="far fa-edit"
                            >&nbsp; {{ $t("order.data.edit") }}</i
                          >
                        </b-button>
                      </span>
                      <span v-else>
                        <b-button
                          size="md"
                          variant="outline-secondary"
                          class="m-1"
                          @click.stop="saveInput('notes')"
                        >
                          <i class="far fa-check-circle p-1" />
                        </b-button>
                        <b-button
                          size="md"
                          variant="outline-secondary"
                          class="m-1"
                          @click.stop="revertInput('notes')"
                        >
                          <i class="far fa-times-circle p-1" />
                        </b-button>
                      </span>
                    </b-form-group>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td colspan="2" class="font-w600">
                    <b-form-group
                      :label="$t('order.data.notesInternal')"
                      label-class="font-w600"
                    >
                      <b-form-textarea
                        id="notesInternal"
                        rows="4"
                        :placeholder="$t('order.data.notesInternalPlaceholder')"
                        v-model="order.notes_internal"
                        :disabled="edit.notes_internal === false"
                      ></b-form-textarea>
                      <div v-if="edit.notes_internal === false">
                        <b-button
                          class="m-1"
                          variant="outline-secondary"
                          @click.stop="editInput('notes_internal')"
                        >
                          <i class="far fa-edit"
                            >&nbsp; {{ $t("order.data.edit") }}</i
                          >
                        </b-button>
                      </div>
                      <div v-else>
                        <b-button
                          size="md"
                          variant="outline-secondary"
                          class="m-1"
                          @click.stop="saveInput('notes_internal')"
                        >
                          <i class="far fa-check-circle p-1" />
                        </b-button>
                        <b-button
                          size="md"
                          variant="outline-secondary"
                          class="m-1"
                          @click.stop="revertInput('notes_internal')"
                        >
                          <i class="far fa-times-circle p-1" />
                        </b-button>
                      </div>
                    </b-form-group>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </base-block>
        </b-col>
      </b-row>

      <b-row v-if="order.origin == 'egfArchive'">
        <b-col sm="12">
          <OrderArticleEgfArchive :order="order" />
        </b-col>
      </b-row>

      <b-row v-if="order.origin == 'metrix'">
        <b-col sm="12">
          <base-block
            :title="$t('order.article.title')"
            header-class="bg-primary"
            themed
          >
            <b-row>
              <b-col
                sm="6"
                v-for="(item, itemKey) in order.items"
                :key="itemKey"
              >
                <base-block rounded>
                  <b-row>
                    <b-col sm="12">
                      <base-block
                        :title="
                          $t('order.article.article') + ' ' + (itemKey + 1)
                        "
                        header-class="bg-primary"
                        class="pt-4"
                        themed
                        ref="image"
                        style="height: auto;"
                      >
                        <b-img
                          fluid
                          rounded
                          class="py-1 px-4"
                          style="border: 1px solid #ededed;"
                          :src="item.details.image[0]"
                        />
                      </base-block>

                      <base-block
                        :title="$t('order.article.article')"
                        header-class="bg-primary"
                        class="pt-4"
                        themed
                        ref="article"
                        style="height: auto;"
                        :style="{ height: layout.articleBlockHeight }"
                      >
                        <b-table-simple
                          responsive
                          borderless
                          small
                          table-class="table-vcenter"
                        >
                          <b-tbody>
                            <b-tr>
                              <b-td class="font-w600" style="width: 35%">
                                {{ $t("order.article.name") }}:
                              </b-td>
                              <b-td>
                                {{ item.name }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="font-w600">
                                {{ $t("order.article.size") }}:
                              </b-td>
                              <b-td>
                                {{ item.details.size }}
                              </b-td>
                            </b-tr>
                            <b-tr v-if="order.origin_id">
                              <b-td class="font-w600" style="width: 35%">
                                {{ $t("order.article.configuration") }}:
                              </b-td>
                              <b-td>
                                {{ order.origin_id }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="font-w600" style="width: 35%">
                                {{ $t("order.article.itemnumber") }}:
                              </b-td>
                              <b-td>
                                {{ item.item_number }}
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </base-block>

                      <base-block
                        :title="$t('order.article.engravings')"
                        header-class="bg-primary"
                        class="pt-4"
                        themed
                        ref="engraving"
                        style="height: auto;"
                        :style="{ height: layout.engravingBlockHeight }"
                      >
                        <b-table-simple
                          responsive
                          borderless
                          small
                          table-class="table-vcenter"
                        >
                          <template v-if="item.engravings.length < 1">
                            <b-tbody>
                              <b-tr>
                                <b-td class="font-w600" style="width: 35%">
                                  {{ $t("order.article.engraving") }}:
                                </b-td>
                                <b-td>
                                  {{ $t("order.article.noEngraving") }}
                                </b-td>
                              </b-tr>
                            </b-tbody>
                          </template>
                          <template v-else>
                            <b-tbody
                              v-for="(engraving, i) in item.engravings"
                              :key="'engraving_id_' + i"
                            >
                              <b-tr>
                                <b-td colspan="2">
                                  <hr v-if="i > 0" />
                                </b-td>
                              </b-tr>
                              <b-tr>
                                <b-td class="font-w600" style="width: 35%">
                                  {{ $t("order.article.engravingPosition") }}:
                                </b-td>
                                <b-td>
                                  {{ engraving.side_text }}
                                </b-td>
                              </b-tr>
                              <b-tr>
                                <b-td class="font-w600">
                                  {{ $t("order.article.engravingText") }}:
                                </b-td>
                                <b-td
                                  v-if="engraving.font_id !== '---'"
                                  :class="'engraving-font-' + engraving.font_id"
                                >
                                  {{ engraving.text }}
                                </b-td>
                                <b-td v-else>
                                  ---
                                </b-td>
                              </b-tr>
                              <b-tr>
                                <b-td class="font-w600">
                                  {{ $t("order.article.engravingFont") }}:
                                </b-td>
                                <b-td
                                  v-if="engraving.font_id !== '---'"
                                  :class="'engraving-font-' + engraving.font_id"
                                >
                                  {{ engraving.font_id }}
                                </b-td>
                                <b-td v-else>
                                  ---
                                </b-td>
                              </b-tr>
                              <template
                                v-if="engraving.individual_id && 1 == 17"
                              >
                                <b-tr>
                                  <b-td class="font-w600">
                                    {{
                                      $t("order.article.engravingIndividual")
                                    }}:
                                  </b-td>
                                  <b-td>
                                    {{ engraving.individual_id }}
                                  </b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td class="font-w600" colspan="2">
                                    <img
                                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4
        //8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=="
                                    />
                                  </b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </template>
                        </b-table-simple>
                      </base-block>

                      <base-block
                        :title="$t('order.article.details.title')"
                        header-class="bg-primary"
                        class="pt-1"
                        style="height: auto;"
                        :style="{ height: layout.detailsBlockHeight }"
                        themed
                        ref="details"
                      >
                        <b-table-simple
                          responsive
                          borderless
                          small
                          table-class="table-vcenter"
                        >
                          <b-tbody>
                            <b-tr v-if="order.order_type != 'P'">
                              <b-td class="font-w600" style="width: 35%">
                                {{ $t("order.article.details.brand") }}:
                              </b-td>
                              <b-td>
                                {{ item.details.details.brand }}
                              </b-td>
                            </b-tr>
                            <b-tr v-if="order.order_type != 'P'">
                              <b-td class="font-w600">
                                {{ $t("order.article.details.colorMetal") }}:
                              </b-td>
                              <b-td>
                                {{ item.details.details.metal }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="font-w600" style="width: 35%">
                                {{ $t("order.article.details.profile") }}:
                              </b-td>
                              <b-td>
                                {{ item.details.details.profile }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="font-w600" style="width: 35%">
                                {{ $t("order.article.details.width") }}:
                              </b-td>
                              <b-td>
                                {{ item.details.details.width }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="font-w600" style="width: 35%">
                                {{ $t("order.article.details.height") }}:
                              </b-td>
                              <b-td>
                                {{ item.details.details.height }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="font-w600">
                                {{ $t("order.article.details.finish") }}:
                              </b-td>
                              <b-td>
                                {{ item.details.details.finish }}
                              </b-td>
                            </b-tr>
                            <b-tr v-if="order.order_type != 'P'">
                              <b-td class="font-w600">
                                {{ $t("order.article.details.grooves") }}:
                              </b-td>
                              <b-td>
                                <span
                                  v-if="item.details.details.grooves.length > 0"
                                >
                                  <span
                                    v-for="(grooves, i) in item.details.details
                                      .grooves"
                                    :key="i"
                                    >{{ grooves }}<br
                                  /></span>
                                </span>
                                <span v-else>
                                  ({{ $t("order.article.details.noGrooves") }}:)
                                </span>
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td colspan="2"><hr /></b-td>
                            </b-tr>
                            <template
                              v-for="(stone, i) in item.details.details.stones"
                            >
                              <b-tr :key="i">
                                <b-td class="font-w700">
                                  {{ $t("order.article.details.stones") }}:
                                </b-td>
                                <b-td> &nbsp; </b-td>
                              </b-tr>
                              <b-tr :key="i + '_setting'">
                                <b-td class="font-w600">
                                  {{
                                    $t("order.article.details.stoneSetting")
                                  }}:
                                </b-td>
                                <b-td> {{ stone.setting }} </b-td>
                              </b-tr>
                              <b-tr :key="i + '_quality'">
                                <b-td class="font-w600">
                                  {{
                                    $t("order.article.details.stoneQuality")
                                  }}:
                                </b-td>
                                <b-td> {{ stone.quality }} </b-td>
                              </b-tr>
                              <b-tr :key="i + '_carat'">
                                <b-td class="font-w600">
                                  {{ $t("order.article.details.stoneCarat") }}:
                                </b-td>
                                <b-td> {{ stone.carat }} </b-td>
                              </b-tr>
                            </template>
                          </b-tbody>
                        </b-table-simple>
                      </base-block>

                      <base-block
                        :title="$t('order.defaultSample')"
                        header-class="bg-primary"
                        class="pt-1"
                        style="height: auto;"
                        themed
                        v-if="order.order_type == 'P'"
                      >
                        <p>
                          <span class="font-w600"
                            >{{
                              $t("orderCreateSample.price.default.sample")
                            }}:</span
                          ><br />
                          {{
                            $t(
                              "orderCreateSample.price.default.sampleDescription",
                            )
                          }}
                        </p>
                      </base-block>

                      <base-block
                        :title="
                          $t(
                            'order.infoForManufacture.title.type_' +
                              order.order_type,
                          )
                        "
                        header-class="bg-primary"
                        class="manufacturing-notes pt-1"
                        style="height: auto;"
                        :style="{
                          height: layout.manufacturingNotesBlockHeight,
                        }"
                        themed
                        ref="manufacturingNotes"
                      >
                        <b-form-textarea
                          rows="4"
                          :placeholder="
                            $t(
                              'order.infoForManufacture.textarea.type_' +
                                order.order_type,
                            )
                          "
                          v-model="item.manufacturing_notes"
                          class="p-2"
                          :disabled="
                            edit.items[itemKey].manufacturing_notes === false
                          "
                        ></b-form-textarea>
                        <div
                          v-if="
                            edit.items[itemKey].manufacturing_notes === false
                          "
                        >
                          <b-button
                            class="m-1"
                            variant="outline-secondary"
                            @click.stop="
                              editItemInput(itemKey, 'manufacturing_notes')
                            "
                            :disabled="order.status.sent_to_supplier == 1"
                          >
                            <i class="far fa-edit"
                              >&nbsp;
                              {{ $t("order.infoForManufacture.edit") }}</i
                            >
                          </b-button>
                        </div>
                        <div v-else>
                          <b-button
                            size="md"
                            variant="outline-secondary"
                            class="m-1"
                            @click.stop="
                              saveItemInput(itemKey, 'manufacturing_notes')
                            "
                          >
                            <i class="far fa-check-circle p-1" />
                          </b-button>
                          <b-button
                            size="md"
                            variant="outline-secondary"
                            class="m-1"
                            @click.stop="
                              revertItemInput(itemKey, 'manufacturing_notes')
                            "
                          >
                            <i class="far fa-times-circle p-1" />
                          </b-button>
                        </div>
                      </base-block>
                    </b-col>
                  </b-row>
                </base-block>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center">
                <hr />
                <b-button
                  size="md"
                  variant="outline-secondary"
                  class="m-1"
                  @click="loadConfigurator()"
                  :disabled="
                    order.status.sent_to_supplier == 1 ||
                      order.status.cancelled == 1
                  "
                >
                  <i class="fa fa-exchange-alt mr-1"></i>
                  {{ $t("order.changeArticles.button") }}
                </b-button>
                <p>
                  <span class="text-red"
                    >{{ $t("order.changeArticles.information") }}:</span
                  >
                  {{ $t("order.changeArticles.informationText") }}
                </p>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
      </b-row>
    </div>

    <div v-if="isOrderIdValid === false">
      <b-row>
        <b-col sm="12">
          <base-block>
            <div
              role="alert"
              aria-live="polite"
              aria-atomic="true"
              class="alert alert-dismissible alert-danger"
            >
              <h3 class="alert-heading h4 my-2">
                {{ $t("order.error.title") }}
              </h3>

              <p class="mb-0">
                {{ $t("order.error.text") }}<br /><br />
                <router-link
                  v-if="shopId"
                  :to="{
                    name: 'Home',
                    params: { shopid: shopId },
                  }"
                >
                  &gt; {{ $t("order.error.backLink") }}
                </router-link>
              </p>
            </div>
          </base-block>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { German } from "flatpickr/dist/l10n/de.js";
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import { Korean } from "flatpickr/dist/l10n/ko.js";

import OrderServiceHeader from "@/components/OrderServiceHeader.vue";
import OrderStatusTimeline from "@/components/OrderStatusTimeline.vue";
import OrderActions from "@/components/OrderActions.vue";
import OrderPrints from "@/components/OrderPrints.vue";
import OrderCosts from "@/components/OrderCosts.vue";

import Loading from "@/store/loading";
import Notification from "@/assets/js/Notification";
import Order from "@/assets/js/Order";
import OrderItem from "@/assets/js/OrderItem";

import Engraving from "@/assets/js/Engraving";
import OrderArticleEgfArchive from "@/components/OrderArticleEgfArchive.vue";

export default {
  name: "OrderDetails",
  components: {
    flatPickr,
    OrderServiceHeader,
    OrderStatusTimeline,
    OrderActions,
    OrderPrints,
    OrderCosts,
    OrderArticleEgfArchive,
  },
  data() {
    return {
      // Get more form https://flatpickr.js.org/options/
      flatPickrConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "Y-m-d", // Date Format displayed in the input field
        altInput: true,
        dateFormat: "Y-m-d", // Date Format used for submitting
        locale: null,
      },
      flatPickrConfigDeliveryDateSupplier: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "Y-m-d", // Date Format displayed in the input field
        altInput: true,
        dateFormat: "Y-m-d", // Date Format used for submitting
        locale: null,
        disable: [
          function(date) {
            let today = new Date();
            today.setHours(0, 0, 0, 0);

            // 2 years from now in the future
            let twoYearsLater = new Date();
            twoYearsLater.setFullYear(twoYearsLater.getFullYear() + 2);
            twoYearsLater.setHours(0, 0, 0, 0);

            // check if the date is in the past
            if (date < today) {
              return true;
            }

            // check if the date is more than 2 years in the future
            if (date > twoYearsLater) {
              return true;
            }

            // check if the date is a weekend
            let day = date.getDay();
            if (day === 6 || day === 0) {
              return true;
            }

            // disable dates between 23.12.2024 and 05.01.2025
            let startHoliday = new Date(2024, 11, 23); // 23.12.2024
            let endHoliday = new Date(2025, 0, 5); // 05.01.2025
            if (date >= startHoliday && date <= endHoliday) {
              return true;
            }

            // date is valid
            return false;
          },
        ],
      },
      isOrderIdValid: false,
      layout: {
        articleBlockHeightInt: null,
        articleBlockHeight: null,
        detailsBlockHeightInt: null,
        detailsBlockHeight: null,
      },
      edit: {
        commission: false,
        delivery_date_supplier: false,
        delivery_date_customer: false,
        wedding_date: false,
        salesperson: false,
        deposit: false,
        notes: false,
        notes_internal: false,
        items: {
          0: {
            manufacturing_notes: false,
          },
          1: {
            manufacturing_notes: false,
          },
          2: {
            manufacturing_notes: false,
          },
          3: {
            manufacturing_notes: false,
          },
        },
      },
      revertValues: {
        commission: "",
        delivery_date_supplier: null,
        delivery_date_customer: null,
        wedding_date: null,
        salesperson: null,
        deposit: 0,
        notes: "",
        notes_internal: "",
        items: {
          0: {
            manufacturing_notes: "",
          },
          1: {
            manufacturing_notes: "",
          },
          2: {
            manufacturing_notes: "",
          },
          3: {
            manufacturing_notes: "",
          },
        },
      },
      orderId: "",
      shopId: "",
      order: {
        order_year: 0,
        order_month: 0,
        order_id: 0,
        order_type: "A",
        parent_order_str: null,
        child_orders: [],
        shop_id: 0,
        type: "",
        commission: "",
        delivery_date_supplier: "2021-01-01",
        delivery_date_customer: "2021-01-01",
        wedding_date: "2021-01-01",
        salesperson: "",
        deposit: 0,
        notes: "",
        notes_internal: "",
        vat: 0,
        currency: "",
        total_amount: 0,
        total_vat_amount: 0,
        status: {
          created: 0,
          created_date: "2021-01-01",
          down_payment: 0,
          down_payment_date: "2021-01-01",
          completely_paid: 0,
          completely_paid_date: "2021-01-01",
          sent_to_supplier: 0,
          sent_to_supplier_date: "2021-01-01",
          in_production: 0,
          in_production_date: "2021-01-01",
          production_finished: 0,
          production_finished_date: "2021-01-01",
          sent_to_customer: 0,
          sent_to_customer_date: "2021-01-01",
          in_shop: 0,
          in_shop_date: "2021-01-01",
          finished: 0,
          finished_date: "2021-01-01",
          cancelled: 0,
          cancelled_date: "2021-01-01",
        },
        items: [
          {
            item_id: 0,
            image: [],
          },
        ],
        origin: "",
        origin_id: "",
      },
    };
  },
  methods: {
    reload(shopid, orderid) {
      this.shopId = shopid;
      this.orderId = orderid;
      this.show(this.shopId, this.orderId);
    },
    loadData(shopId, orderId) {
      return Order.get(shopId, orderId);
    },
    show(shopId, orderId) {
      Loading.dispatch("increase", 1);

      this.loadData(shopId, orderId)
        .then(data => {
          this.order = data.order;
          this.isOrderIdValid = true;

          /* load Fonts */
          if (data == "egfB2B") {
            for (var key in this.order.items) {
              let item = this.order.items[key];

              for (var engravingKey in item["engravings"]) {
                let fontId = item["engravings"][engravingKey]["font_id"];

                if (fontId != "---") {
                  Engraving.loadFont(fontId);
                }
              }
            }
          }

          Loading.dispatch("reduce", 2);
        })
        .then(() => this.matchArticleHeight())
        .then(() => this.matchEngravingHeight())
        .then(() => this.matchDetailsHeight())
        .catch(function(err) {
          console.log("No order data available.", err);
          Loading.dispatch("reduce", 1);
        });
    },

    checkInput(key) {
      const keys_with_date = {
        delivery_date_supplier: "date",
        delivery_date_customer: "date",
        wedding_date: "date",
      };

      if (key in keys_with_date) {
        if (this.order[key] == "") {
          Notification.push(
            this.$t("notification.info"),
            this.$t("order.notification.emptyDate"),
            "info",
          );
          return false;
        }
      }

      return true;
    },
    editInput(key) {
      this.revertValues[key] = this.order[key];
      this.edit[key] = !this.edit[key];
    },
    saveInput(key) {
      if (this.checkInput(key)) {
        Order.update(this.shopId, this.orderId, key, this.order[key]).then(
          result => {
            if (result === false) {
              Notification.push(
                this.$t("notification.error"),
                this.$t("notification.changesNotSaved"),
                "danger",
              );

              this.edit[key] = false;
            } else {
              Notification.push(
                this.$t("notification.success"),
                this.$t("notification.changesSaved"),
                "success",
              );

              this.edit[key] = false;
            }
          },
        );
      }
    },
    revertInput(key) {
      this.order[key] = this.revertValues[key];
      this.revertValues[key] = "";
      this.edit[key] = false;
    },
    editItemInput(itemKey, key) {
      this.revertValues["items"][itemKey][key] = this.order.items[itemKey][key];
      this.edit["items"][itemKey][key] = !this.edit["items"][itemKey][key];
    },
    saveItemInput(itemKey, key) {
      Loading.dispatch("increase");

      OrderItem.update(
        this.shopId,
        this.orderId,
        this.order.items[itemKey]["item_id"],
        key,
        this.order.items[itemKey][key],
      ).then(result => {
        if (result === false) {
          Notification.push(
            this.$t("notification.error"),
            this.$t("notification.changesNotSaved"),
            "danger",
          );
          Loading.dispatch("reduce");
        } else {
          Notification.push(
            this.$t("notification.success"),
            this.$t("notification.changesSaved"),
            "success",
          );
          this.edit.items[itemKey][key] = false;
          Loading.dispatch("reduce");
        }
      });
    },
    revertItemInput(itemKey, key) {
      this.order.items[itemKey][key] = this.revertValues["items"][itemKey][key];
      this.revertValues["items"][itemKey][key] = "";
      this.edit["items"][itemKey][key] = false;
    },
    setOrderCompletelyPaid() {
      this.order.deposit = this.order.total_amount;
      /* this.order.balance_amount = 0 */
      this.saveInput("deposit");
    },
    matchArticleHeight() {
      if (typeof this.$refs.article != "undefined") {
        if (
          typeof this.$refs.article[0] !== "undefined" &&
          typeof this.$refs.article[0].$el.clientHeight != "undefined"
        )
          this.layout.articleBlockHeightInt = this.$refs.article[0].$el.clientHeight;
        {
          this.layout.articleBlockHeight =
            this.layout.articleBlockHeightInt + "px";
          let numberOfArticles = this.$refs.article.length;
          if (numberOfArticles > 1) {
            let i = 0;
            for (i = 0; i < numberOfArticles; i++) {
              if (
                this.$refs.article[i].$el.clientHeight >
                this.layout.articleBlockHeightInt
              ) {
                this.layout.articleBlockHeightInt = this.$refs.article[
                  i
                ].$el.clientHeight;
                this.layout.articleBlockHeight =
                  this.layout.articleBlockHeightInt + "px";
              }
            }
          }
        }
      }
    },
    matchEngravingHeight() {
      if (typeof this.$refs.engraving != "undefined") {
        if (
          typeof this.$refs.engraving[0] !== "undefined" &&
          typeof this.$refs.engraving[0].$el.clientHeight != "undefined"
        )
          this.layout.engravingBlockHeightInt = this.$refs.engraving[0].$el.clientHeight;
        {
          this.layout.engravingBlockHeight =
            this.layout.engravingBlockHeightInt + "px";
          let numberOfArticles = this.$refs.engraving.length;
          if (numberOfArticles > 1) {
            let i = 0;
            for (i = 0; i < numberOfArticles; i++) {
              if (
                this.$refs.engraving[i].$el.clientHeight >
                this.layout.engravingBlockHeightInt
              ) {
                this.layout.engravingBlockHeightInt = this.$refs.engraving[
                  i
                ].$el.clientHeight;
                this.layout.engravingBlockHeight =
                  this.layout.engravingBlockHeightInt + "px";
              }
            }
          }
        }
      }
    },
    matchDetailsHeight() {
      if (typeof this.$refs.details != "undefined") {
        if (
          typeof this.$refs.details[0] !== "undefined" &&
          typeof this.$refs.details[0].$el.clientHeight != "undefined"
        ) {
          this.layout.detailsBlockHeightInt = this.$refs.details[0].$el.clientHeight;
          this.layout.detailsBlockHeight =
            this.layout.detailsBlockHeightInt + "px";
          let numberOfDetails = this.$refs.details.length;
          if (numberOfDetails > 1) {
            let i = 0;
            for (i = 0; i < numberOfDetails; i++) {
              if (
                this.$refs.details[i].$el.clientHeight >
                this.layout.detailsBlockHeightInt
              ) {
                this.layout.detailsBlockHeightInt = this.$refs.details[
                  i
                ].$el.clientHeight;
                this.layout.detailsBlockHeight =
                  this.layout.detailsBlockHeightInt + "px";
              }
            }
          }
        }
      }
    },
    loadConfigurator() {
      const redirectUrl =
        "/" + this.shopId + "/orders/configurator/" + this.orderId;
      this.$router.push(redirectUrl);
    },
    reinitializeFlatpickr() {
      if (this.$refs.FlatPickrDeliveryDateCustomer) {
        const flatpickrInstanceDeliveryDateCustomer = this.$refs
          .FlatPickrDeliveryDateCustomer.flatpickr;
        if (flatpickrInstanceDeliveryDateCustomer) {
          flatpickrInstanceDeliveryDateCustomer.destroy();
          this.$refs.flatPickr.flatpickr(this.flatPickrConfig);
        }
      }

      if (this.$refs.FlatPickrDeliveryDateSupplier) {
        const flatpickrInstanceDeliveryDateSupplier = this.$refs
          .FlatPickrDeliveryDateSupplier.flatpickr;
        if (flatpickrInstanceDeliveryDateSupplier) {
          flatpickrInstanceDeliveryDateSupplier.destroy();
          this.$refs.flatPickr.flatpickr(
            this.flatPickrConfigDeliveryDateSupplier,
          );
        }
      }

      if (this.$refs.FlatPickrWeddingDate) {
        const flatpickrInstanceWeddingDate = this.$refs.FlatPickrWeddingDate
          .flatpickr;
        if (flatpickrInstanceWeddingDate) {
          flatpickrInstanceWeddingDate.destroy();
          this.$refs.flatPickr.flatpickr(this.flatPickrConfig);
        }
      }
    },
  },
  watch: {
    "$i18n.locale": {
      handler(newLocale) {
        const locales = {
          de: German,
          nl: Dutch,
          fr: French,
          ja: Japanese,
          ko: Korean,
        };
        this.flatPickrConfig.locale = locales[newLocale] || null;

        switch (newLocale) {
          case "de":
            this.flatPickrConfig.altFormat = "d.m.Y";
            this.flatPickrConfigDeliveryDateSupplier.altFormat = "d.m.Y";
            break;
          case "nl":
            this.flatPickrConfig.altFormat = "d-m-Y";
            this.flatPickrConfigDeliveryDateSupplier.altFormat = "d-m-Y";
            break;
          case "fr":
            this.flatPickrConfig.altFormat = "d/m/Y";
            this.flatPickrConfigDeliveryDateSupplier.altFormat = "d/m/Y";
            break;
          case "ja":
            this.flatPickrConfig.altFormat = "Y/m/d";
            this.flatPickrConfigDeliveryDateSupplier.altFormat = "Y/m/d";
            break;
          case "ko":
            this.flatPickrConfig.altFormat = "Y.m.d";
            this.flatPickrConfigDeliveryDateSupplier.altFormat = "Y.m.d";
            break;
          default:
            this.flatPickrConfig.altFormat = "Y-m-d";
            this.flatPickrConfigDeliveryDateSupplier.altFormat = "Y-m-d";
            break;
        }

        this.$nextTick(() => {
          this.reinitializeFlatpickr();
        });
      },
      immediate: true,
    },
  },
  computed: {
    VUE_APP_BUILD_ENV() {
      return process.env.VUE_APP_BUILD_ENV;
    },
  },
  mounted() {
    this.shopId = this.$route.params.shopid;
    this.orderId = this.$route.params.orderid;

    Notification.init(this);

    this.show(this.shopId, this.orderId);
  },
};
</script>

<style lang="scss" scoped="scoped">
// Flatpickr + Custom overrides
@import "~flatpickr/dist/flatpickr.css";
@import "./src/assets/scss/vendor/flatpickr";
</style>
