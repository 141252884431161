<template>
  <section>
    <base-background :image="headerImage" inner-class="bg-secondary-dark-op">
      <div class="content content-narrow content-full">
        <div
          class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mt-5 mb-2 text-center text-sm-left"
        >
          <div class="flex-sm-fill">
            <h1 class="font-w600 text-white mb-0">
              {{ $t("dashboard.title") }}
            </h1>
            <h2 class="h4 font-w400 text-white-75 mb-0">
              {{ $t("dashboard.subtitle") }}
            </h2>
          </div>
        </div>
      </div>
    </base-background>
    <div class="content content-narrow">
      <b-row>
        <!-- Latest Orders -->
        <b-col xl="12">
          <base-block
            rounded
            :title="$t('dashboard.boxes.latestOrders.title')"
            header-bg
            content-full
          >
            <b-table-simple
              striped
              hover
              borderless
              class="table-vcenter font-size-sm mb-0"
            >
              <b-thead>
                <b-tr>
                  <b-th class="font-w700">{{
                    $t("dashboard.boxes.latestOrders.orderNumber")
                  }}</b-th>
                  <b-th class="d-none d-sm-table-cell font-w700">{{
                    $t("dashboard.boxes.latestOrders.deliveryDate")
                  }}</b-th>
                  <b-th class="font-w700">{{
                    $t("dashboard.boxes.latestOrders.commission")
                  }}</b-th>
                  <b-th class="font-w700">{{
                    $t("dashboard.boxes.latestOrders.status")
                  }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="filteredList.length > 0">
                <b-tr v-for="order in filteredList" :key="order.order_id_str">
                  <b-td>
                    <router-link
                      class="font-w600"
                      v-if="order.shop_id && order.order_id_str"
                      :to="{
                        name: 'OrderDetail',
                        params: {
                          shopid: order.shop_id,
                          orderid: order.order_id_str,
                        },
                      }"
                    >
                      {{ order.order_id_str }}
                    </router-link>
                  </b-td>
                  <b-td class="d-none d-sm-table-cell">
                    {{ $d(new Date(order.delivery_date_supplier), "short") }}
                  </b-td>
                  <b-td>
                    {{ order.commission }}
                  </b-td>
                  <b-td>
                    {{ $t("orders.status." + order.current_status) }}
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else>
                <b-tr>
                  <b-td colspan="4" class="text-center">
                    <div class="py-5">
                      <p class="font-size-h3 font-w300 mb-2">
                        {{ $t("dashboard.boxes.latestOrders.noOrders") }}
                      </p>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </base-block>
        </b-col>
        <!-- END Latest Orders -->
      </b-row>
    </div>
  </section>
</template>

<script>
import _ from "lodash";

import Loading from "@/store/loading";
import OrdersList from "@/assets/js/OrdersList";

export default {
  components: {},
  name: "HomeView",
  data() {
    return {
      shopId: 0,
      tableSortKey: "created_datetime",
      tableSortDirection: "desc",
      latestOrders: [],
    };
  },
  methods: {
    async showList(shopId) {
      Loading.dispatch("increase", 1);

      const data = await OrdersList.getListActive(shopId);

      if (data.length > 0) {
        for (let orderData of data) {
          this.latestOrders.push(orderData);
        }
      }
      Loading.dispatch("reduce", 1);
    },
  },
  computed: {
    filteredList() {
      return _.slice(
        _.orderBy(
          this.latestOrders,
          [this.tableSortKey],
          [this.tableSortDirection],
        ),
        0,
        10,
      );
    },
    currentShopId() {
      return this.$route.params.shopid;
    },
    headerImage() {
      const appBrand = this.$store.getters.appBrand;

      if (appBrand == "acredo") {
        return "/img/photos/acredo.webp";
      }

      const today = new Date();
      const month = today.getMonth() + 1;
      let pic = "summer";

      switch (month) {
        case 12:
        case 1:
        case 2:
          pic = "winter";
          break;
        case 3:
        case 4:
        case 5:
          pic = "spring";
          break;
        case 6:
        case 7:
        case 8:
          pic = "summer";
          break;
        case 9:
        case 10:
        case 11:
          pic = "fall";
          break;
      }
      return "/img/photos/" + pic + "@2x.jpg";
    },
  },
  mounted() {
    document.title = this.$t("dashboard.title");

    const self = this;
    setTimeout(function() {
      if (typeof self.currentShopId !== "undefined")
        self.showList(self.currentShopId);
    }, 1000);
  },
};
</script>
