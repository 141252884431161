<template>
  <base-block
    :title="$t('order.article.title')"
    header-class="bg-primary"
    themed
  >
    <b-row>
      <b-col sm="6" v-for="(item, itemKey) in order.items" :key="itemKey">
        <base-block rounded>
          <b-row>
            <b-col sm="12">
              <base-block
                :title="$t('order.article.article') + ' ' + (itemKey + 1)"
                header-class="bg-primary"
                class="pt-4"
                themed
                ref="image"
                style="height: auto;"
              >
                <b-img
                  fluid
                  rounded
                  class="py-1 px-4"
                  style="border: 1px solid #ededed;"
                  :src="item.image"
                />
              </base-block>

              <base-block
                :title="$t('order.article.article')"
                header-class="bg-primary"
                class="pt-4"
                themed
                ref="article"
                style="height: auto;"
              >
                <b-table-simple
                  responsive
                  borderless
                  small
                  table-class="table-vcenter"
                >
                  <b-tbody>
                    <b-tr>
                      <b-td class="font-w600" style="width: 35%">
                        {{ $t("order.article.name") }}:
                      </b-td>
                      <b-td>
                        {{ item.name }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="font-w600">
                        {{ $t("order.article.size") }}:
                      </b-td>
                      <b-td>
                        {{ item.size }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="font-w600" style="width: 35%">
                        {{ $t("order.article.itemnumber") }}:
                      </b-td>
                      <b-td>
                        {{ item.item_number }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </base-block>

              <base-block
                :title="$t('order.article.engravings')"
                header-class="bg-primary"
                class="pt-4"
                themed
                ref="engraving"
                style="height: auto;"
              >
                <b-table-simple
                  responsive
                  borderless
                  small
                  table-class="table-vcenter"
                >
                  <template v-if="item.engravings.length < 1">
                    <b-tbody>
                      <b-tr>
                        <b-td class="font-w600" style="width: 35%">
                          {{ $t("order.article.engraving") }}:
                        </b-td>
                        <b-td>
                          {{ $t("order.article.noEngraving") }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </template>
                  <template v-else>
                    <b-tbody
                      v-for="(engraving, i) in item.engravings"
                      :key="'engraving_id_' + i"
                    >
                      <b-tr>
                        <b-td colspan="2">
                          <hr v-if="i > 0" />
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600" style="width: 35%">
                          {{ $t("order.article.engravingPosition") }}:
                        </b-td>
                        <b-td>
                          {{ engraving.side_text }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600">
                          {{ $t("order.article.engravingText") }}:
                        </b-td>
                        <b-td v-if="engraving.font_id !== '---'">
                          {{ engraving.text }}
                        </b-td>
                        <b-td v-else>
                          ---
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600">
                          {{ $t("order.article.engravingFont") }}:
                        </b-td>
                        <b-td v-if="engraving.font_id !== '---'">
                          {{ engraving.font_id }}
                        </b-td>
                        <b-td v-else>
                          ---
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </template>
                </b-table-simple>
              </base-block>

              <!-- Article Details -->

              <base-block
                :title="$t('order.article.details.title')"
                header-class="bg-primary"
                class="pt-1"
                style="height: auto;"
                themed
                ref="details"
              >
                <b-table-simple
                  responsive
                  borderless
                  small
                  table-class="table-vcenter"
                >
                  <b-tbody>
                    <b-tr v-if="order.order_type != 'P'">
                      <b-td class="font-w600" style="width: 35%">
                        {{ $t("order.article.details.brand") }}:
                      </b-td>
                      <b-td>
                        {{ item.details.details.brand }}
                      </b-td>
                    </b-tr>

                    <b-tr>
                      <b-td class="font-w600" style="width: 35%">
                        {{ $t("order.article.details.profile") }}:
                      </b-td>
                      <b-td>
                        {{ item.details.details.profile }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="font-w600" style="width: 35%">
                        {{ $t("order.article.details.width") }}:
                      </b-td>
                      <b-td>
                        {{ item.details.details.width }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="font-w600" style="width: 35%">
                        {{ $t("order.article.details.height") }}:
                      </b-td>
                      <b-td>
                        {{ item.details.details.height }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td colspan="2"><hr /></b-td>
                    </b-tr>
                    <template v-if="item.details.details.stone_groups">
                      <b-tr>
                        <b-td class="font-w700">
                          Steinbesatz:
                        </b-td>
                        <b-td> vorhanden<br />Details in PDF </b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
              </base-block>

              <base-block
                :title="$t('order.infoForManufacture.title.type_A')"
                header-class="bg-primary"
                class="manufacturing-notes pt-1"
                style="height: auto;"
                themed
                ref="manufacturingNotes"
              >
                {{ item.manufacturing_notes }}
              </base-block>
            </b-col>
          </b-row>
        </base-block>
      </b-col>
    </b-row>
  </base-block>
</template>

<script setup>
import { defineProps, onMounted } from "vue";

const props = defineProps({
  order: Object,
});

onMounted(() => {
  if (props.order.items.length < 1) {
    console.log("No items found order.");
  }
});
</script>
