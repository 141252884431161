import { render, staticRenderFns } from "./OrderB2B.vue?vue&type=template&id=7759d357&scoped=true&"
import script from "./OrderB2B.vue?vue&type=script&lang=js&"
export * from "./OrderB2B.vue?vue&type=script&lang=js&"
import style0 from "./OrderB2B.vue?vue&type=style&index=0&id=7759d357&prod&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7759d357",
  null
  
)

export default component.exports